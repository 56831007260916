td.noRec-found {
  padding: 30px 0 50px;
}

.main-sidebar .nav .nav-item .nav-link i.material-icons {
  font-size: 24px;
}

.page-link {
  padding: 15px;
  color: #49a837;
}

.page-link:hover {
  color: #49a837;
}

.page-item.active .page-link {
  color: #fff;
  background-color: #49a837;
  border-color: #49a837;
}

.action-btn {
  width: 40px;
  height: 40px;
  font-size: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.action-btn > i {
  font-size: 20px;
}

.pagination {
  margin-top: 15px;
}

.main-sidebar .nav .nav-item .nav-link.active,
.main-sidebar .nav .nav-item .nav-link:hover,
.main-sidebar .nav .nav-item.active {
  box-shadow: inset 0.1875rem 0 0 #49a837;
  color: #49a837;
}

.main-sidebar .nav .nav-item .nav-link.active i,
.main-sidebar .nav .nav-item .nav-link:hover i,
.main-sidebar .nav .nav-item.active i {
  color: #49a837;
}

.page-link:focus {
  box-shadow: none;
}

i.material-icons {
  top: 0;
}

.btn-primary {
  color: #fff;
  border-color: #49a837;
  background-color: #49a837;
  box-shadow: none;
}

.btn-primary:hover {
  border-color: #235e18;
  background-color: #235e18;
  box-shadow: none;
}

/* .hide {
    display: none;
} */
label {
  display: block;
}

.form-control:focus {
  border-color: #49a837 !important;
  box-shadow: 0 0.313rem 0.719rem rgb(73 168 55 / 10%),
    0 0.156rem 0.125rem rgb(0 0 0 / 6%) !important;
}

.btn {
  height: 40px;
  align-items: center;
  justify-content: center;
}

.form-control {
  height: 40px;
}

/* Dashboard dark css */
.main-sidebar {
  background-color: #191919;
}

/* .main-navbar nav .navbar-brand1 {
  background-color: #191919;
} */

.main-navbar nav .navbar-brand {
  background-color: #191919;
}

.main-navbar nav .navbar-brand span {
  color: #fff;
}

.main-sidebar .nav .nav-item .nav-link {
  color: #fff;
}

.main-sidebar .nav .nav-item .nav-link.active,
.main-sidebar .nav .nav-item .nav-link:hover,
.main-sidebar .nav .nav-item.active {
  box-shadow: inset 0.1875rem 0 0 #ffffff;
  color: #ffffff;
  background-color: #49a837;
}

.main-sidebar .nav .nav-item .nav-link.active i,
.main-sidebar .nav .nav-item .nav-link:hover i,
.main-sidebar .nav .nav-item.active i {
  color: #ffffff;
}

.main-sidebar .nav .nav-item .nav-link:hover {
  background-color: hsla(0, 0%, 78%, 0.2);
}

.main-sidebar .nav .nav-item:hover {
  box-shadow: inset 0.1875rem 0 0 #ffffff;
  color: #ffffff;
  background-color: hsla(0, 0%, 78%, 0.2);
}

.main-sidebar .nav .nav-item .nav-link.active:hover,
.main-sidebar .nav .nav-item.active:hover {
  background-color: #49a837;
}

.main-sidebar .nav-wrapper {
  border-top: 1px solid rgb(255 255 255 / 50%);
}

.main-navbar .navbar,
.main-sidebar .navbar-brand,
.main-navbar .navbar .nav-link {
  height: 4.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-content > .main-content-container.container-fluid {
  min-height: calc(100vh - 8.5rem);
}

.main-sidebar .nav-wrapper {
  height: calc(100vh - 4.75rem - 1px);
}

.table td,
.table th {
  white-space: nowrap;
}

.main-navbar .navbar .nav-link {
  cursor: pointer;
  min-width: 10rem;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(0, 0, 0);
  background: #fff;
}

::-webkit-scrollbar-thumb {
  background: #49a837;
}

::-webkit-scrollbar-thumb:hover {
  background: #044214;
}

::-webkit-scrollbar:horizontal {
  height: 10px;
  background-color: red;
}

::-webkit-scrollbar-thumb:horizontal {
  background: #49a837;
  border-radius: 0px;
}

.toggle-btn {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px;
  background-color: #49a837;
  color: #fff;
  cursor: pointer;
  transition: 0.3s;
}

.toggle-btn:hover {
  border-radius: 5px;
  background-color: #191919;
}

.modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.table td {
  white-space: normal;
}

.elipis-text p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin: 0;
}

html,
body {
  height: 100%;
}

.auth-section {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fce8d1;
}

.auth-container {
  width: 450px;
}

/* .auth-logo{filter: brightness(0) invert(1);} */
/* .shadow-none{box-shadow: none!important;} */
.with-link-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.with-link-label a {
  font-size: 13px;
  color: #044214;
}

.login-from-bx .form-group {
  margin-bottom: 2rem;
}

.page-header .page-subtitle {
  text-transform: initial !important;
}

.modal-backdrop.show {
  opacity: 0;
}

.notifications .notifications-count {
  display: inline-block;
  position: absolute;
  top: -8px;
  right: -8px;
  background-color: #f1556c;
  color: #fff;
  font-size: 10px;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.Toastify__toast-container{z-index: 999999!important;}

/* Sidebar css */
.main-sidebar,
.main-content {
  transition: 0.3s !important;
}

.main-sidebar {
  height: 100%;
}

.main-sidebar .nav-wrapper {
  height: calc(100% - 4.75rem - 1px);
}

.main-sidebar {
  transition: transform 450ms ease, width 450ms ease;
}

body.sidebar-sticky .main-sidebar,
body.sidebar-sticky .main-sideba:not(:hover) {
  max-width: 5rem;
  left: 0;
}

body.sidebar-sticky .main-sidebar .nav .nav-item .nav-link span,
body.sidebar-sticky .main-sidebar .nav .nav-item .nav-link span:not(:hover) {
  display: none;
}

body.sidebar-sticky .main-navbar nav .navbar-brand span,
body.sidebar-sticky .main-navbar nav .navbar-brand span:not(:hover) {
  display: none !important;
}

body.sidebar-sticky .main-sidebar .nav .nav-item .nav-link,
body.sidebar-sticky .main-sidebar .nav .nav-item .nav-link:not(:hover) {
  padding: 0.9375rem 1rem;
  text-align: center;
}

body.sidebar-sticky aside + .main-content,
body.sidebar-sticky aside + .main-content:not(:hover) {
  margin-left: 5rem;
  max-width: calc(100% - 5rem);
  width: 100%;
  flex: 0 0 100%;
}

body.sidebar-sticky aside.main-sidebar:hover {
  max-width: 16.666667%;
  left: 0;
}

body.sidebar-sticky .main-sidebar:hover .nav .nav-item .nav-link span {
  display: inline-block;
}

body.sidebar-sticky .main-sidebar:hover nav .navbar-brand span {
  display: inline-block !important;
}

body.sidebar-sticky .main-sidebar:hover .nav .nav-item .nav-link {
  padding: 0.9375rem 1.5625rem;
  text-align: left;
}

body.sidebar-sticky aside:hover + .main-content {
  margin-left: 16.666667%;
  max-width: auto;
  width: 100%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.toggle-btn {
  min-width: 40px;
  margin-right: 0;
}

.react-datepicker__input-container input[type="text"] {
  min-height: 46px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 0 10px;
}

.custom-select {
  min-width: 160px;
  padding: 0 10px;
  min-height: 46px;
}

.react-datepicker {
  padding: 15px;
}

.subscription-select-bx {
  display: flex;
  align-items: center;
}

.subscription-select-bx .custom-select {
  min-width: 5rem;
  height: 40px !important;
  min-height: unset;
}

.subscription-select-bx label {
  margin: 0 5px 0 0;
  font-weight: 600;
}

@media (max-width: 767.98px) {
  .main-navbar .navbar .notifications {
    position: relative;
  }

  body.sidebar-sticky aside + .main-content,
  body.sidebar-sticky aside + .main-content:not(:hover) {
    margin-left: 10rem;
    max-width: 100%;
  }

  body.sidebar-sticky .main-sidebar,
  body.sidebar-sticky .main-sideba:not(:hover) {
    max-width: 10rem;
    transform: unset;
  }

  body.sidebar-sticky .main-sidebar .nav .nav-item .nav-link span,
  body.sidebar-sticky .main-sidebar .nav .nav-item .nav-link span:not(:hover) {
    display: inline-block;
    font-size: 10px;
  }

  body.sidebar-sticky .main-sidebar .nav .nav-item .nav-link,
  body.sidebar-sticky .main-sidebar .nav .nav-item .nav-link:not(:hover) {
    text-align: left;
    padding: 0.9375rem 0.5rem;
  }

  .main-sidebar,
  .main-content {
    transition: unset !important;
  }

  body.sidebar-sticky aside.main-sidebar:hover {
    max-width: 10rem;
    left: 0;
  }

  .m-4 {
    margin: 0 1rem !important;
  }

  body.sidebar-sticky .main-sidebar:hover nav .navbar-brand span {
    display: none !important;
  }

  .main-sidebar__search {
    display: none !important;
  }

  .page-header .page-title {
    margin-bottom: 1rem;
  }

  .table td {
    white-space: nowrap;
  }

  strong {
    white-space: normal !important;
  }

  body.sidebar-sticky .main-navbar .navbar {
    flex-wrap: nowrap;
  }

  a.notification {
    display: none !important;
  }

  .main-navbar .navbar .nav-link {
    min-width: 5rem;
  }
  body.sidebar-sticky aside + .main-content, body.sidebar-sticky aside + .main-content:not(:hover) {flex: unset;}
}

.promotion-slider-table tr td p, .banner-management-table tr td p {
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.promotion-slider-table tr td:last-child, .banner-management-table tr td:last-child {
  white-space: nowrap;
}

@media (max-width: 1399px ) {
  .promotion-slider-table img, .banner-management-table img {
    max-height: 60px;
    max-width: 100px;
    object-fit: contain;
  }
}

@media (max-width: 991px ) {
  .promotion-slider-table tr td p, .banner-management-table tr td p { 
    max-width: 300px;
    min-width: 300px;
    -webkit-line-clamp: 2;
  }
}

@media (max-width: 575px) {
  .promotion-slider-table img, .banner-management-table img {
    max-height: 40px;
    max-width: 100px;
  }
}

@media (max-width: 460px) {
  .page-header .page-title {
    font-size: 1.6rem;
  }
}

@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.m-spin {
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
}


.react-datepicker__input-container input[type="text"]{width: 100%;}
.react-datepicker-popper{z-index: 9999;}
.text-denger{color: rgb(243, 61, 61);}