strong {
  font-weight: 700 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.dashboard-bx-custom .stats-small__data {
  border-bottom: 1px solid #CCC;
  margin-bottom: 15px;
  padding-bottom: 10px;
}


@media (max-width:767px) {
  .mobile-block {display: block !important;}
  .react-datepicker__input-container input[type="text"] {width: 100%;}
  .mobile-block .custom-select {margin: 15px 0;}
}